<route>
{
  "meta": {
    "auth": "reservationManage"
  }
}
</route>

<template>
  <div>
    <bm-cardTabList :list="tabList" current="预约规则"></bm-cardTabList>
    <div class="tableContainer">
      <div class="header" style="margin: 16px 0">
        <span class="title">规则列表</span>
        <el-button type="success" style="float: right" size="small" @click="handleAdd">添加新预约规则 </el-button>
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="ruleName" label="规则名称"> </el-table-column>
        <el-table-column align="center" prop="store_staff_count" label="特定日">
          <template slot-scope="scope">
            <el-button v-if="scope.row.hasSpecialDay" text>有</el-button>
            <el-button v-else text>无</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="240px">
          <template slot-scope="scope">
            <el-button v-auth="'reservationEdit'" @click="handleEdit(scope.row.id)" type="text" size="small"
              >编辑
            </el-button>
            <!-- <el-button
              v-auth="'reservationDelete'"
              type="text"
              @click="handleDelete(scope)"
              size="small"
              class="deleteBtnText"
              >删除
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 删除组件 -->
    <bm-deleteItem
      url="boom-center-product-service/sysAdmin/bookingRule/"
      :timestamp="timestamp"
      @successDelete="successDelete"
    ></bm-deleteItem>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
import { deleteRowItem } from '@/mixin/deleteRowItem'

import { tabListExcludeAccount, tabListIncludeAccount } from '../tabList'
export default {
  mixins: [pagination, deleteRowItem],
  data() {
    return {
      list: [],
      tabList: [],
      timestamp: 0
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.getters['vendor/params/getBusinessModel'] == 0) {
        vm.tabList = tabListIncludeAccount
      } else {
        vm.tabList = tabListExcludeAccount
      }
    })
  },
  methods: {
    handleAdd() {
      this.$store.commit('vendor/params/updateParams', { key: 'reservationId', value: '' })
      this.$router.push(`/vendor/vendorMange/reservation/edit`)
    },

    // 请求后端获取预约规则列表
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1

      const { list, total } = this.$http
        .get('/boom-center-product-service/sysAdmin/bookingRule/list', {
          params: {
            businessId: this.$store.getters['vendor/params/getVendorId'],
            page: this.currentPage,
            size: this.pageSize
          }
        })
        .then(res => {
          this.list = res.list
          this.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },

    handleEdit(id) {
      this.$store.commit('vendor/params/updateParams', { key: 'reservationId', value: id })
      this.$router.push(`/vendor/vendorMange/reservation/edit`)
    }
  }
}
</script>

<style scoped lang="less">
.tableContainer {
  background-color: #fff;
  padding: @container-margin;
}
.header {
  padding: 0;
  margin: 0;
}
.product-info {
  display: flex;
  align-items: center;

  .title {
    vertical-align: middle;
  }
}
</style>
